export interface FirmwarePackage {
	key: string;
	version: string;
}

export function isLatestFirmwarePackage(
	currentVersion: string | undefined,
	availablePackages: FirmwarePackage[]
): boolean {
	if (currentVersion === undefined) {
		return false;
	}
	return (
		currentVersion.length > 0 &&
		availablePackages.length > 0 &&
		makeComparableFirmwareVersion(currentVersion) >= makeComparableFirmwareVersion(availablePackages[0].version)
	);
}

export function makeComparableFirmwareVersion(value: string): string {
	if (value.length > 0) {
		return value
			.split('.')
			.map((n) => n.toString().padStart(5, '0')) // max of 65535 per hextet
			.join('');
	}

	return '9'.repeat(5 * 4); // highest value
}
